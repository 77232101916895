import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  products: [],
  property: {},
  resto_props: {},
  remove_product: {},
  current_order_count: 0,
  floors:[],
};


export const getAllTables = createAsyncThunk("cart/getAllTables", async () => {
  const response = await fetch("https://howinpos.supooi.com/connector/api/get-all-tables/30");
  const data = await response.json();
  return data;
});


// export default function (state = initialState, action) {

//     console.log(action.payload, 'action.payload', action.type);
//     switch (action.type) {
//         case LOAD_CART:
//             return {
//                 ...state,
//                 products: action.payload
//             };
//         case ADD_PRODUCT:
//             return {
//                 ...state,
//                 productToAdd: Object.assign({}, action.payload)
//             };
//         case REMOVE_PRODUCT:
//             return {
//                 ...state,
//                 productToRemove: Object.assign({}, action.payload)
//             };
//         case CART_PROPERTIES:
//             return {
//                 ...state,
//                 property: action.payload
//             };
//         case RESTO_PROPS:
//             return {
//                 ...state,
//                 resto_props: action.payload
//             };
//         default:
//             return { ...state };
//     }
// }
const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addProduct: (state, action) => {
      const newItem = action.payload;
      state.productToAdd = Object.assign({}, newItem)
      // const checkProduct = state.products.filter(item => item.id === newItem.id);
      // if (newItem && newItem.products) {
      // 	const products = newItem.products.filter(item => item.id === newItem.id);
      // 	if (products.length) {
      // 		const newAddons = newItem.selectedaddons ? newItem.selectedaddons.map(addon => addon.addon_id) : [];
      // 		const findProducts = products.map((item) => {
      // 			let additem = { ...item };
      // 			const oldAddons = item && item.selectedaddons ? item.selectedaddons.map(addon => addon.addon_id) : [];
      // 			const isSame = oldAddons.length === newAddons.length && oldAddons.every((value, index) => value === newAddons[index]);
      // 			if (isSame) {
      // 				const quantity = newItem.update_item && newItem.update_item === true ? item.quantity : item.quantity + 1;
      // 				if (item.max_qty) {
      // 					if (item.max_qty != quantity) {
      // 						additem.quantity = quantity;
      // 					}
      // 				} else {
      // 					additem.quantity = quantity;
      // 				}
      // 				return additem;
      // 			}
      // 		})
      // 		if (findProducts) {
      // 			state.products = [...state.products, ...findProducts]
      // 		} else {
      // 			delete newItem.products;
      // 			state.products = [...state.products, newItem]
      // 		}
      // 		console.log(products, findProducts, 'checkProduct');
      // 	} else {
      // 		const removeProducts = { ...newItem };
      // 		delete removeProduct.products;
      // 		delete removeProduct.addons_check;
      // 		state.products = [...state.products, removeProducts]
      // 	}
      // } else {
      // 	if (checkProduct.length) {
      // 		console.log(checkProduct.selectedaddons, 'state.products', checkProduct);
      // 		if (checkProduct.selectedaddons) {
      // 			const newAddons = newItem.selectedaddons.map(addon => addon.addon_id);
      // 			const alreadyAddons = checkProduct.selectedaddons.map(addon => addon.addon_id);
      // 			const isSame = newAddons.length === alreadyAddons.length && newAddons.every((value, index) => value === alreadyAddons[index]);
      // 			if (isSame) {
      // 				const quantity = newItem.update_item && newItem.update_item === true ? state.products.find(item => item.id === newItem.id).quantity + 0 : state.products.find(item => item.id === newItem.id).quantity + 1;
      // 				if (checkProduct.max_qty) {
      // 					if (checkProduct.max_qty != quantity) {
      // 						state.products.find(item => item.id === newItem.id).quantity = quantity;
      // 					}
      // 				} else {
      // 					state.products.find(item => item.id === newItem.id).quantity = quantity;
      // 				}
      // 			} else {
      // 				state.products = [...state.products, newItem]
      // 			}
      // 		} else {
      // 			const quantity = newItem.update_item && newItem.update_item === true ? state.products.find(item => item.id === newItem.id).quantity + 0 : state.products.find(item => item.id === newItem.id).quantity + 1;
      // 			// console.log(checkProduct, 'state.products', checkProduct);
      // 			if (checkProduct.max_qty) {
      // 				if (checkProduct.max_qty != quantity) {
      // 					state.products.find(item => item.id === newItem.id).quantity = quantity;
      // 				}
      // 			} else {
      // 				state.products.find(item => item.id === newItem.id).quantity = quantity;
      // 			}
      // 		}
      // 	} else {
      // 		// console.log(newItem, 'newItem');
      // 		state.products = [...state.products, newItem]
      // 	}
      // }
      // console.log(newItem, 'newItem');
      // if (state.productToAdd) {
      // 	state.productToAdd = { ...newItem }
      // } else {
      // 	state.productToAdd = newItem
      // }
      // return state;
    },
    clearAddProduct: (state, action) => {
      // console.log(undefined, 'productToAdd');
      state.productToAdd = undefined;
    },
    loadCart: (state, _) => {
      state.products = [...state.products]
    },
    clearRemoveProduct: (state, action) => {
      state.remove_product = undefined;
    },
    setCurrentOrderCount: (state, action) => {
      state.current_order_count = action.payload;
    },
    removeProduct: (state, action) => {
      const newItem = action.payload;
      // state.remove_product = newItem
      state.productToRemove = Object.assign({}, newItem)
    },
    cartProperties: (state, action) => {
      state.property = action.payload
    },
    restoProps: (state, action) => {
      state.resto_props = action.payload
    },
    updateCartProduct: (state, action) => {
      const newItem = action.payload.item
      state.products = state.products.filter(item => item.id !== newItem.id);
      if (newItem.quantity > 0) {
        state.products = [...state.products, newItem]
      }
    },
    updateProducts: (state, action) => {
      if (action.payload) {
        state.products = [...action.payload];
      } else {
        state.products = [];
      }
    },
  },
  extraReducers: (builder) => {

    builder.addCase(getAllTables.fulfilled, (state, action) => {
      if (action?.payload?.data?.floors) {
        state.floors = action?.payload?.data?.floors
      }
    })
  }
})

export const {
  addProduct,
  loadCart,
  removeProduct,
  cartProperties,
  restoProps,
  clearRemoveProduct,
  updateCartProduct,
  clearAddProduct,
  updateProducts,
  setCurrentOrderCount,
} = cartSlice.actions

export const getRemoveProduct = ((state) => state.cart.remove_product)
export const getProducts = ((state) => state.cart.products)
export const getProductToAdd = ((state) => state.cart.productToAdd)
export const getCurrentOrderCount = ((state) => state.cart.current_order_count)

export default cartSlice.reducer;